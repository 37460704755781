<template>
  <div class="row">
    <div class="col-12">
      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filters"
              aria-expanded="false"
              aria-controls="filters"
            >
              {{ $t("events.filters.title") }} ({{
                $t("events.filters.click_to_open")
              }})
            </button>
          </h2>

          <div
            id="filters"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#filterAccordion"
          >
            <div class="accordion-body">
              <Form
                @submit="(values) => getAlerts(1, values)"
                :validation-schema="schema"
                ref="form"
              >
                <div class="row">
                  <div class="row m-t-xs">
                    <div class="col-md-3 col-xs-6 col-xxs-6 col-xxs-12">
                      <label for="alertIDfilter" class="form-label">
                        Alert ID
                      </label>
                      <Field type="text" name="alertIDfilter" class="form-control" id="alertIDfilter" placeholder="Enter Alert ID"/>
                      <ErrorMessage name="alertIDfilter" class="error-feedback"/>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <label for="siteIDFilter" class="form-label">Site</label>
                      <Multiselect
                          v-if="sites.length > 0"
                          :class="{'multi-dark': $store.state.auth.status.dark}"
                          mode="tags"
                          placeholder="Select a site"
                          :close-on-select="true"
                          :searchable="true"
                          :create-option="false"
                          :multiple="false"
                          :options="sites"
                          label="name"
                          track-by="id"
                          name="site"
                          v-model="selectedSite"
                      />
                      <ErrorMessage name="postcode" class="error-feedback"/>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-6 col-xxs-12">
                      <label for="startDateHappenedFilter" class="form-label">{{ "Alert Start Date" }}</label>
                      <Field type="date" name="startDateHappenedFilter" class="form-control" id="startDateHappenedFilter"/>
                      <ErrorMessage name="startDateHappenedFilter" class="error-feedback"/>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-6 col-xxs-12">
                      <label for="endDateHappenedFilter" class="form-label">{{ "Alert End Date" }}</label>
                      <Field type="date" name="endDateHappenedFilter" class="form-control" id="endDateHappenedFilter"/>
                      <ErrorMessage name="endDateHappenedFilter" class="error-feedback"/>
                    </div>
                  </div>
                  <div class="row m-t-md" style="justify-content: center">
                    <div class="" style="width: auto">
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                        style="max-width: 150px"
                      >
                        <!-- Show spinning icon when dataTable is loading -->
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm m-r-xxs"
                        ></span>
                        <span>{{ $t("events.filters.search") }}</span>
                      </button>
                    </div>
                    <div class="col-12 width-auto" style="width: auto">
                      <button
                        type="reset"
                        :disabled="loading"
                        class="btn btn-secondary"
                        style="max-width: 150px"
                        @click="clearFilter"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm m-r-xxs"
                        ></span>
                        <span>{{ $t("global.clear_filter") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <br>

      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1 class="table-heading" style="padding: 0; margin: 0">
          {{ $t("navigation.side.alerts") }} 
        </h1>

        <p style="font-style: italic;">Alert Images are only available for 7 days after the alert has been generated.</p>
      </div>

      <Pagination v-show="!loading" :config="pagination" @search="(page) => getAlerts(page, currentParams)"/>

      <div class="card m-t-sm">
        <div class="card-body">
          <table class="table table-borderless" v-show="!loading">
            <thead>
              <tr>
                <th scope="col" style="width: 10%;">ID</th>
                <th scope="col" style="width: 20%;">Site</th>
                <th scope="col" style="width: 20%;">Similarity</th>
                <th scope="col" style="width: 20%;">Alert Date/Time</th>
                <th scope="col" style="max-width: 15%;">Alert Image</th>
                <th scope="col" style="max-width: 15%;">Incident Image</th>
              </tr>
            </thead>
            <tbody v-for="alert in alerts" :key="alert.id" >
              <tr>
                <td>{{ alert.id }}</td>
                <td>{{ getSiteName(alert.site_id) }}</td>
                <td>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{ width: alert.similarity + '%' }"
                      :aria-valuenow="alert.similarity"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ alert.similarity.toFixed(2) }}%
                    </div>
                  </div>
                </td>
                <td>{{ convertDate(alert.created_at) }}</td>
                <td style="max-width: 10%;">
                  <viewer :options="viewerOptions">
                    <img 
                      :src="alert.alertSas"
                      alt="AlertImg"
                      @contextmenu.prevent
                      @click="saveScrollPosition"
                      class="fixed-size-img"
                    />
                  </viewer>
                </td>
                <td style="max-width: 10%;">
                  <viewer :options="viewerOptions">
                    <img 
                      :src="alert.incidentSas"
                      alt="IncidentImg"
                      @contextmenu.prevent
                      @click="saveScrollPosition"
                      class="fixed-size-img"
                    />
                  </viewer>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Pagination v-show="!loading" :config="pagination" @search="(page) => getAlerts(page, currentParams)"/>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";
import { Form, Field, ErrorMessage } from "vee-validate";
import { Multiselect } from "vue-multiselect";
import Pagination from "../../components/Pagination.vue";
import * as yup from "yup";

export default {
  name: 'Alerts',
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    Pagination,
  },
  data() {
    const schema = yup.object().shape({
      alertIDfilter: yup.string().nullable(),
      startDateHappenedFilter: yup.date().nullable().transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
      endDateHappenedFilter: yup.date().nullable().transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    });
    return {
      loading: false,
      schema,
      pagination: {
        page: 1,
        showing: 25,
        maxPage: 1,
        total: 0
      },
      alerts: [],
      site: null,
      sites: [],
      selectedSite: null,
      currentParams: {},
      scrollPosition: 0,
      viewerOptions: {
        viewed() {
          this.viewer.zoomTo(3);
        },
        hidden: () => {
          this.restoreScrollPosition();
        },
      }
    }
  },
  async created() {
    await this.getSites();
  },
  methods: {
    async getSites() {
      try {
        const response = await ClientService.getClientSites(
          this.$store.state.auth.user.selectedClient,
          null,
          null,
          5
        );
        this.sites = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        if (this.sites.length > 0) {
          this.selectedSiteId = this.sites[0].id;
          this.restoreFiltersFromSessionStorage();
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.error("Error loading sites:", error);
        this.loading = false;
      }
    },
    getAlerts(page = 1, filters = {}) {
      this.loading = true;

      const params = new URLSearchParams({
        type: "standard",
        site_id: this.selectedSite.id,
        page: page
      })

      this.currentParams = filters;

      if (filters.alertIDfilter) {
        params.append("alert_id", filters.alertIDfilter);
      }
      if (filters.startDateHappenedFilter) {
        params.append("created_at_start", filters.startDateHappenedFilter);
      }
      if (filters.endDateHappenedFilter) {
        params.append("created_at_end", filters.endDateHappenedFilter);
      }

      this.saveFiltersToSessionStorage();

      axios.get(`https://api.varsanpr.com/customer/alert/portalAlerts?${params.toString()}`, {
        headers: authHeader()
      })
        .then(response => { 
          this.alerts = response.data.alerts;
          this.pagination = {
            page: page,
            maxPage: response.data.pagination.total > 0 ? Math.ceil(response.data.pagination.total / this.pagination.showing) : 1,
            total: response.data.pagination.total,
            showing: this.pagination.showing
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    saveFiltersToSessionStorage() {
      const filtersToSave = {
        selectedSite: this.selectedSite ? this.selectedSite.id : null,
        alertIDfilter: this.currentParams.alertIDfilter || null,
        startDateHappenedFilter: this.currentParams.startDateHappenedFilter || null,
        endDateHappenedFilter: this.currentParams.endDateHappenedFilter || null
      };
      sessionStorage.setItem("portalAlertFilters", JSON.stringify(filtersToSave));
    },
    restoreFiltersFromSessionStorage() {
      try {
        const savedFilters = sessionStorage.getItem("portalAlertFilters");
        if (!savedFilters) {
          this.getAlerts();
          return;
        }
        
        const filters = JSON.parse(savedFilters);
        
        if (filters.selectedSite && this.sites.length > 0) {
          this.selectedSite = this.sites.find(site => site.id === filters.selectedSite) || null;
        }
        
        const formValues = {
          alertIDfilter: filters.alertIDfilter || null,
          startDateHappenedFilter: filters.startDateHappenedFilter || null,
          endDateHappenedFilter: filters.endDateHappenedFilter || null
        };
        
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.setValues(formValues);
            this.getAlerts(1, formValues);
          } else {
            this.getAlerts();
          }
        });
      } catch (error) {
        console.error('Error restoring filters from session storage:', error);
        this.getAlerts();
      }
    },
    selectAlert(alert) {
      this.$router.push({
        path: `/faces/alerts/${alert.site_id}/${alert.id}`,
      });
    },
    getSiteName(siteId) {
      const site = this.sites.find(site => site.id === String(siteId));
      return site ? site.name : 'Unknown';
    },
    convertDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    },
    clearFilter() {
      this.$refs.form.resetForm();
      this.currentParams = {};
      sessionStorage.removeItem("portalAlertFilters");
      this.getAlerts();
    },
    saveScrollPosition() {
      this.scrollPosition = window.scrollY;
    },
    restoreScrollPosition() {
      window.scrollTo(0, this.scrollPosition);
    },
  },
  computed: {
    selectedSiteId: {
      get() {
        return this.selectedSite ? this.selectedSite.id : null;
      },
      set(value) {
        this.selectedSite = this.sites.find((site) => site.id === value);
      },
    },
  },
}
</script>

<style scoped>
.fixed-size-img {
  width: 250px;
  height: 300px;
  object-fit: cover;
}
</style>